import { useMemo } from "react";
import { getGridDateOperators, GridColDef } from "@mui/x-data-grid-pro";
import { IconButton, Typography, Link as MUILink } from "@mui/material";
import { Link } from "react-router-dom";

import { options as caseTypeOptions } from "../components/shared/Form/CaseTypeSelect";
import {
  ArrowIconStyled,
  nestedValueGetter,
  renderCell,
  renderDateCell,
  renderHeader,
  renderNoHeader,
} from "../components/shared/DataGridHelpers";

import { CaseAvatar } from "../components/shared/AllListGrid/TypeAvatar";
import GridItem from "../components/shared/AllListGrid/GridItem";
import { PrettyStatus } from "../components/shared/PrettyStatus";
import { Case } from "auditaware-types";
import useAssignees from "./useAssignees";

const useCaseColumns = () => {
  const assignees = useAssignees();

  const assigneeOptions = useMemo(
    () => [
      { value: "unassigned", label: "Unassigned" },
      ...assignees.map(({ id: value, displayName: label }) => ({ value, label })),
    ],
    [assignees]
  );

  const caseColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "caseType",
        type: "singleSelect",
        valueOptions: caseTypeOptions,
        headerName: "Type",
        renderHeader,
        width: 80,
        renderCell: ({ row }) => <CaseAvatar caseType={row.caseType} />,
      },
      {
        field: "status",
        headerName: "Status",
        renderHeader,
        flex: 1,
        renderCell: (params) => (
          <GridItem title={params.row.status} variant="body2" sx={{ fontWeight: 900 }}>
            <PrettyStatus status={params.row.status} />
          </GridItem>
        ),
      },
      {
        field: "cycle",
        headerName: "Cycle",
        renderHeader,
        flex: 1,
        renderCell,
      },
      {
        field: "location.parcelId",
        headerName: "Parcel ID",
        renderHeader,
        flex: 1,
        valueGetter: nestedValueGetter,
        renderCell,
      },
      {
        field: "subject.externalId",
        headerName: "Unique ID",
        renderHeader,
        flex: 1,
        valueGetter: nestedValueGetter,
        renderCell,
      },
      {
        field: "subject.contact.primaryName",
        headerName: "Subject",
        flex: 1,
        renderHeader,
        valueGetter: nestedValueGetter,
        renderCell,
      },
      {
        field: "location.countyName",
        headerName: "County",
        flex: 1,
        valueGetter: nestedValueGetter,
        renderHeader,
        renderCell,
      },
      {
        field: "location.jurisdictionName",
        headerName: "Jurisdiction",
        flex: 1,
        valueGetter: nestedValueGetter,
        renderHeader,
        renderCell,
      },
      {
        type: "singleSelect",
        field: "assignee.displayName",
        valueOptions: assigneeOptions,
        headerName: "Assignee",
        renderHeader,
        renderCell: ({ row }) => <Typography>{row.assignee?.displayName}</Typography>,
        valueGetter: ({ row }) => row.assignee?.id || "unassigned",
        flex: 1,
      },
      {
        field: "scheduledOn",
        headerName: "Scheduled Date",
        renderHeader,
        renderCell: renderDateCell,
        flex: 1,
        filterOperators: getGridDateOperators(true),
      },
      {
        field: "confirmationSentOn",
        headerName: "Confirmation Sent On",
        renderHeader,
        renderCell: renderDateCell,
        flex: 1,
        filterOperators: getGridDateOperators(true),
      },
      {
        field: "documentsReceivedOn",
        headerName: "Docs Received On",
        renderHeader,
        renderCell: renderDateCell,
        flex: 1,
        filterOperators: getGridDateOperators(true),
      },
      {
        field: "primaryContact.displayName",
        headerName: "Primary Name",
        renderHeader,
        renderCell: (params) => {
          const { primaryContact } = params.row;
          const displayName =
            [primaryContact?.primaryName, primaryContact?.secondaryName]
              .join(" ")
              .trim() || " ";

          return <Typography>{displayName}</Typography>;
        },
        valueGetter: nestedValueGetter<Case>,
        flex: 1,
      },
      {
        field: "primaryContact.phoneNumber",
        headerName: "Primary Phone",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
      },
      {
        field: "primaryContact.email",
        headerName: "Primary Email",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
      },
      {
        field: "location.address",
        headerName: "Location Address",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
      },
      {
        field: "location.city",
        headerName: "Location City",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
      },
      {
        field: "location.stateAbbr",
        headerName: "Location State",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
      },
      {
        field: "location.zipCode",
        headerName: "Location Zip",
        renderHeader,
        renderCell,
        valueGetter: nestedValueGetter,
        flex: 1,
      },
      {
        field: "id",
        headerName: "Link",
        renderHeader: renderNoHeader,
        sortable: false,
        disableColumnMenu: true,
        width: 80,
        renderCell: (params) => {
          return (
            <MUILink
              component={Link}
              to={`/cases/${params.value}`}
              underline="none"
              color="inherit"
            >
              <IconButton aria-label="View Button" size="large">
                <ArrowIconStyled />
              </IconButton>
            </MUILink>
          );
        },
      },
    ],
    [assigneeOptions]
  );

  return caseColumns;
};

export default useCaseColumns;
