import { FC, useCallback, useMemo, useState } from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { DataGridToolbar } from "./DataGridToolbar";
import { TableContainer, styled } from "@mui/material";
import { usePersistentState } from "../../hooks/usePersistentState";
import { wordToCamelCase } from "../../lib/wordToCamelCase";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  border: 0,
  "& .MuiDataGrid-withBorderColor": {
    borderColor:
      theme.palette.mode === "dark" // eslint-disable-next-line operator-linebreak
        ? "#000" // eslint-disable-next-line operator-linebreak
        : "rgba(0, 0, 0, 0.08)",
  },
}));

type DataGridProps = DataGridProProps & {
  rows: GridValidRowModel[];
  columns: GridColDef[];
  hiddenColumns?: string[];
  title: string;
  headerHeight?: number;
  settingsPrefix?: string;
  filtersKey?: string;
  visibilityKey?: string;
  densityKey?: string;
}

type Density = DataGridProProps["density"];

export const DataGrid: FC<DataGridProps> = ({
  rows,
  columns,
  hiddenColumns,
  headerHeight,
  title,
  density = "comfortable",
  settingsPrefix = wordToCamelCase(title),
  filtersKey = [settingsPrefix, "filters"].join("."),
  visibilityKey = [settingsPrefix, "visibility"].join("."),
  densityKey = [settingsPrefix, "density"].join("."),
  ...rest
}) => {
  const [isFilterActive, setFilterActive] = useState(false);
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [columnsButtonEl, setColumnsButtonEl] = useState(null);

  const [filters, setFilters] = usePersistentState<
    GridFilterModel | null
  >(filtersKey, null);

  const [columnVisibility, setColumnVisibility] = usePersistentState<
    GridColumnVisibilityModel | null
  >(visibilityKey, null);

  const [persistedDensity, setPersistedDensity] = usePersistentState<
    typeof density | undefined
  >(densityKey, density);

  const handleStateChange = useCallback((state: { density: { value: Density } }) => {
    if (state.density.value !== persistedDensity) {
      setPersistedDensity(state.density.value);
    }
  }, [persistedDensity, setPersistedDensity]);

  const initialState = useMemo(() => {
    if (!hiddenColumns) return {};

    return hiddenColumns.reduce(
      (acc, column) => {
        acc.columns.columnVisibilityModel[column] = false;
        return acc;
      },
      { columns: { columnVisibilityModel: {} as Record<string, boolean> } }
    );
  }, [hiddenColumns]);

  return (
    <TableContainer style={{ width: "100%", overflowX: "auto" }}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        initialState={initialState}
        columnHeaderHeight={headerHeight || 48}
        density={persistedDensity}
        onStateChange={handleStateChange}
        slots={{
          toolbar: DataGridToolbar,
        }}
        slotProps={{
          panel: {
            anchorEl: isFilterActive ? filterButtonEl : columnsButtonEl,
          },
          toolbar: {
            title,
            setColumnsButtonEl,
            setFilterButtonEl,
            setFilterActive,
          },
        }}
        filterModel={filters || undefined}
        onFilterModelChange={setFilters}
        columnVisibilityModel={columnVisibility || undefined}
        onColumnVisibilityModelChange={setColumnVisibility}
        {...rest}
      />
    </TableContainer>
  );
};
