import { FC, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { Paper, Grid, Box, Typography, Button } from "@mui/material";

import { Outlet, useParams } from "react-router-dom";

import { CustomDrawer, useDrawer } from "auditaware-ui";
import { Location, OrganizationContact, ResidenceData } from "auditaware-types";

import EditSubject from "./EditSubject";
import { useSubject } from "../../../hooks/subjectHooks";
import { List, Item } from "../../shared/List";
import { SubjectAvatar } from "../../shared/AllListGrid/TypeAvatar";
import OverviewBox from "../../shared/OverviewBox";
import OrganizationView from "./OrganizationView";
import SubjectTypeSwitcher from "../../shared/SubjectTypeSwitcher";
import ResidenceView from "./ResidenceView";

type OrganizationSubject = {
  location?: Location;
  contact?: OrganizationContact;
};

const TitleBox = styled(Box, {
  shouldForwardProp: (prop) => true,
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const StyledEmail = styled("span")(() => ({
  fontWeight: 700,
}));

const Subject: FC = () => {
  const { subjectId = "" } = useParams();
  const { loading, error, data } = useSubject(subjectId);
  const { toggleDrawer } = useDrawer();

  const subject = data?.organization?.subject;

  const { location, contact }: OrganizationSubject = subject || {};

  const switcherMap = useMemo(
    () => ({
      Organization: (
        <OrganizationView
          firstAuditYear={subject?.firstAuditYear}
          naics={contact?.naics}
        />
      ),
      Residence: <ResidenceView residenceData={subject?.subjectData as ResidenceData} />,
      Individual: <></>,
    }),
    [subject, contact]
  );

  if (error) {
    console.error(error);
    return null;
  }

  const objectsUnavailable = !subject || !location || !contact;
  if (loading || objectsUnavailable) return null;

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            borderRadius: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginBottom: "1rem",
          }}
        >
          <Box sx={{ display: "inline-flex", justifyContent: "space-between" }}>
            <Box>
              <Typography
                component="h1"
                variant="h5"
                sx={{ fontWeight: 500 }}
                gutterBottom
              >
                <TitleBox>
                  <SubjectAvatar subjectType={subject.subjectType} />
                  &nbsp;
                  <span>{contact.primaryName}</span>
                </TitleBox>
              </Typography>
              <Typography gutterBottom variant="body2">
                {location.address}, {location.city}, {location.stateAbbr}{" "}
                {location.zipCode}
                <br />
                <span>Parcel ID: {subject.location.parcelId}</span>
              </Typography>
            </Box>
            <Box component="div" ml={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                aria-label="Edit Subject"
                title="Edit Subject"
                sx={{ borderRadius: 24 }}
                onClick={() => toggleDrawer("editSubject")}
              >
                Edit Subject
              </Button>
              <CustomDrawer
                title="Edit Subject"
                drawerId="editSubject"
                content={<EditSubject />}
              />
            </Box>
          </Box>
          <OverviewBox>
            <Grid container spacing={2}>
              <Grid item md lg={6}>
                <List>
                  <Item
                    primary="Email"
                    secondary={<StyledEmail>{contact.email}</StyledEmail>}
                  />
                  <Item primary="Phone Number" secondary={contact.phoneNumber} />
                  <Item primary="Secondary Name" secondary={contact.secondaryName} />
                  <Item primary="Unique ID" secondary={subject.externalId} />
                </List>
              </Grid>
              <Grid item md lg={6}>
                <List>
                  <Item primary="Website" secondary={contact.website} />
                  <Item primary="Jurisdiction" secondary={location.jurisdictionName} />
                  <Item primary="County" secondary={location.countyName} />
                </List>
              </Grid>
            </Grid>
          </OverviewBox>
          <SubjectTypeSwitcher
            subjectType={subject.subjectType}
            switcherMap={switcherMap}
          />
        </Paper>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Subject;
