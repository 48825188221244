import React from "react";

export const usePersistentState = <T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] => {
  const [value, setValue] = React.useState<T>(() => {
    const existingValue = localStorage.getItem(key);
    return existingValue ? JSON.parse(existingValue) : initialValue;
  });

  const setValueWithPersistence = (value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
    setValue(value);
  };

  return [value, setValueWithPersistence];
};
