import { useCallback } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { gql } from "@apollo/client";

import { RoleGuard, SSOAuthBoundary } from "@reasongcp/react-fire-sub";

import { useApolloClientWithAuth } from "auditaware-auth";
import { CMRoles } from "auditaware-types";
import { Layout } from "auditaware-ui";

import Subject from "../../pages/Subject";
import Cases from "../../pages/Cases";
import Case from "../../pages/Case";
import Subjects from "../../pages/Subjects";
import AllCases from "../../pages/Cases/All";
import useConfig from "../../../hooks/useConfig";

import {
  OrganizationProvider,
  NoAuth,
  LoginRedirect,
  LoadingPlaceholder,
} from "auditaware-auth";

const pages = [
  { id: 1, name: "Cases", link: "/cases" },
  { id: 2, name: "Subjects", link: "/subjects" },
];

const AppRoutes = () => {
  const { fireSubApi, ssoClient } = useConfig();
  const firesubClient = useApolloClientWithAuth(fireSubApi, false);

  const LR = useCallback(() => <LoginRedirect ssoClient={ssoClient} />, [ssoClient]);
  const NA = useCallback(() => <NoAuth ssoClient={ssoClient} />, [ssoClient]);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <SSOAuthBoundary
            gql={gql}
            client={firesubClient}
            InvalidPlaceHolder={LR}
            LoadingPlaceHolder={LoadingPlaceholder}
          >
            <OrganizationProvider>
              <Layout appName="Case Manager" ssoClient={ssoClient} pages={pages}>
                <RoleGuard roles={[...CMRoles] as string[]} NoAuth={NA}>
                  <Routes>
                    <Route path="cases" element={<AllCases />} />
                    <Route path="cases/:caseId" element={<Case />} />
                    <Route path="subjects" element={<Subjects />} />
                    <Route path="subjects/:subjectId" element={<Subject />}>
                      <Route path="cases" element={<Cases />} />
                    </Route>
                    <Route
                      path="/"
                      element={<Navigate replace to="/cases" />}
                    />
                  </Routes>
                </RoleGuard>
              </Layout>
            </OrganizationProvider>
          </SSOAuthBoundary>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
