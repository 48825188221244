import { useState } from "react";
import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";

const useColumnVisibilityModelProps =
  ( defaultVisibilityModel: GridColumnVisibilityModel ) => {
    const [model, setModel] =
      useState<GridColumnVisibilityModel>(defaultVisibilityModel);


    return {
      columnVisibilityModel: model,
      onColumnVisibilityModelChange: setModel,
    };
  };

export default useColumnVisibilityModelProps;
