import { GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import useColumnVisibilityModelProps from "./useColumnVisibilityModelProps";

const defaultCaseColumns: GridColumnVisibilityModel = {
  "location.countyName": false,
  "location.parcelId": false,
  "subject.externalId": false,
  "location.jurisdictionName": false,
  "location.address": false,
  "location.city": false,
  "location.stateAbbr": false,
  "location.zipCode": false,
  confirmationSentOn: false,
  documentsReceivedOn: false,
};

const useCaseColumnVisibilityModelProps = () =>
  useColumnVisibilityModelProps(defaultCaseColumns);

export default useCaseColumnVisibilityModelProps;
