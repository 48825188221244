import { Box, Divider, Typography } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";

interface DataGridToolbarProps {
  title: string;
  setColumnsButtonEl: (x: unknown) => void;
  setFilterButtonEl: (x: unknown) => void;
  setFilterActive: (x: boolean) => void;
}

export const DataGridToolbar = ({
  title,
  setColumnsButtonEl,
  setFilterButtonEl,
  setFilterActive,
}: DataGridToolbarProps) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 2,
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          {title}
        </Typography>
        <GridToolbarContainer>
          <GridToolbarFilterButton
            ref={setFilterButtonEl}
            componentsProps={{
              button: {
                onClick: () => {
                  setFilterActive(true);
                },
              },
            }}
          />
          <GridToolbarColumnsButton
            ref={setColumnsButtonEl}
            onClick={() => {
              setFilterActive(false);
            }}
          />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </GridToolbarContainer>
      </Box>
      <Divider light />
    </Box>
  );
};
