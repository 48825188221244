import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import {
  options as subjectTypeOptions,
} from "../components/shared/Form/SubjectTypeSelect";
import { SubjectAvatar } from "../components/shared/AllListGrid/TypeAvatar";
import { usStateSelectOptions } from "auditaware-types";
import {
  nestedValueGetter,
  renderCell,
  renderHeader,
  renderLinkCell,
  renderNoHeader,
} from "../components/shared/DataGridHelpers";

export const useSubjectDataGrid = () => {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "subjectType",
        type: "singleSelect",
        valueOptions: subjectTypeOptions,
        headerName: "Type",
        width: 80,
        renderHeader,
        renderCell: (params) => {
          const { subjectType } = params.row;

          return <SubjectAvatar subjectType={subjectType} />;
        },
        sortable: false,
      },
      {
        field: "location.parcelId",
        valueGetter: nestedValueGetter,
        headerName: "Parcel ID",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "externalId",
        headerName: "Unique ID",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "contact.primaryName",
        valueGetter: nestedValueGetter,
        headerName: "Name",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "contact.naics",
        valueGetter: nestedValueGetter,
        headerName: "NAICS",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "location.countyName",
        valueGetter: nestedValueGetter,
        headerName: "County",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "location.jurisdictionName",
        valueGetter: nestedValueGetter,
        headerName: "Jurisdiction",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "location.address",
        valueGetter: nestedValueGetter,
        headerName: "Address",
        renderHeader,
        flex: 1,
        renderCell,
      },
      {
        field: "location.city",
        valueGetter: nestedValueGetter,
        headerName: "City",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "location.stateAbbr",
        valueGetter: nestedValueGetter,
        type: "singleSelect",
        valueOptions: usStateSelectOptions,
        getOptionLabel: (value: any) => value.value,
        headerName: "State",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "location.zipCode",
        valueGetter: nestedValueGetter,
        headerName: "Zip Code",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "contact.website",
        valueGetter: nestedValueGetter,
        headerName: "Website",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "contact.phoneNumber",
        valueGetter: nestedValueGetter,
        headerName: "Phone #",
        renderHeader,
        renderCell,
        flex: 1,
      },
      {
        field: "path",
        headerName: "Path",
        renderHeader: renderNoHeader,
        sortable: false,
        disableColumnMenu: true,
        width: 80,
        renderCell: renderLinkCell,
      },
    ],
    []
  );

  return columns;
};
