import { FC, useCallback, useMemo } from "react";
import { Grid, Box, Paper, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSubjects } from "../../../hooks/subjectHooks";
import { useSubjectDataGrid } from "../../../hooks/useSubjectDataGrid";
import { DataGrid } from "../../shared/DataGrid";
import CreateSubject from "../Subject/CreateSubject";
import { CustomDrawer, useDrawer } from "auditaware-ui";

const Subjects: FC = () => {
  const { subjects } = useSubjects();
  const columns = useSubjectDataGrid();
  const { toggleDrawer } = useDrawer();

  const linkedSubjects = useMemo(() => {
    return subjects.map((s) => {
      return { ...s, href: `/subjects/${s.id}/cases` };
    });
  }, [subjects]);

  const handleFabClick = useCallback(() => {
    toggleDrawer("createSubject");
  }, [toggleDrawer]);

  return (
    <Box width="100%">
      <Grid container spacing={2}>
        <Grid item xs lg={12}>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "12px",
            }}
          >
            <DataGrid
              title="Subjects"
              rows={linkedSubjects}
              columns={columns}
              headerHeight={30}
              hiddenColumns={[
                "createdAt",
                "updatedAt",
                "id",
              ]}
              settingsPrefix="subjects"
            />
          </Paper>
          <Fab
            sx={{ position: "fixed", bottom: "2rem", right: "6rem" }}
            color="primary"
            aria-label="add subject"
            onClick={handleFabClick}
            title="Add Subject"
            type="button"
          >
            <AddIcon />
          </Fab>
          <CustomDrawer
            title="Create Subject"
            drawerId="createSubject"
            content={<CreateSubject />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Subjects;
