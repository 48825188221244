import { FC } from "react";
import { Grid } from "@mui/material";
import { Item, List } from "../../shared/List";
import OverviewBox from "../../shared/OverviewBox";
import { ResidenceData } from "auditaware-types";

type ResidenceViewProps = {
  residenceData: ResidenceData;
};

const ResidenceView: FC<ResidenceViewProps> = ({
  residenceData: { exemptionPercentage, score, lastSaleOn },
}) => {
  return (
    <OverviewBox>
      <Grid container spacing={2}>
        <Grid item md lg={6}>
          <List>
            <Item primary="Exemption Percentage" secondary={exemptionPercentage} />
            <Item primary="Score" secondary={score ? score : "Not Scored"} />
            <Item primary="Last Sale On" secondary={lastSaleOn} />
          </List>
        </Grid>
      </Grid>
    </OverviewBox>
  );
};

export default ResidenceView;
