import { FC } from "react";
import { Grid } from "@mui/material";
import { Item, List } from "../../shared/List";
import OverviewBox from "../../shared/OverviewBox";
import { OrganizationContact, Subject } from "auditaware-types";

type OrganizationViewProps = {
  firstAuditYear?: Subject["firstAuditYear"];
  naics: OrganizationContact["naics"];
};

const OrganizationView: FC<OrganizationViewProps> = ({ firstAuditYear, naics }) => {
  return (
    <OverviewBox>
      <Grid container spacing={2}>
        <Grid item md lg={6}>
          <List>
            <Item primary="Year Opened" secondary={firstAuditYear} />
            <Item primary="NAICS" secondary={naics} />
          </List>
        </Grid>
      </Grid>
    </OverviewBox>
  );
};

export default OrganizationView;
