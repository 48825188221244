type AddressProps = {
  address?: string,
  city?: string,
  stateAbbr?: string,
  zipCode?: string,
}

const Address = (props: AddressProps) => {
  const line3 = [props.city, `${props.stateAbbr} ${props.zipCode}`]
    .filter((s) => s && !!s.trim())
    .join(", ");

  return (
    <>
      {props.address && (
        <>{props.address}<br/></>
      )}
      {line3}
    </>
  );
};

export default Address;
